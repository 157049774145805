import * as classNames from 'classnames';
import styles from './Icon.module.scss';
import { getIcon } from './iconHelper';
import icons from './iconHelper';

export type Icons = typeof icons;

export type IconProps = {
    icon: keyof Icons;
    height?: number;
    width?: number;
    style?: any;
    className?: string;
    color?: string;
} & React.SVGProps<SVGSVGElement>;

export const Icon = ({
    icon,
    height = 30,
    width = 30,
    style = {},
    className = undefined,
    color,
    ...props
}: IconProps) => {
    const SvgIcon = getIcon(icon);
    if (!SvgIcon) {
        return null;
    }
    return (
        <SvgIcon
            height={height}
            width={width}
            className={classNames(styles.icon, className)}
            style={{
                ...(color ? { fill: `#${color}` } : {}),
                ...(color ? { stroke: `#${color}` } : {}),
                ...style,
            }}
            fill={color}
            {...props}
        />
    );
};

export default Icon;
