import {
    FilterSearchAction,
    IncrementPageAction,
    PopulateSearchParamsAction,
    ResetFiltersAction,
    SearchTourAction,
} from '@/redux/actions/tourSearch';
import { TourSearchParams } from '@/core/tourSearch';
import { TourSearchRequest, TourSearchResponse } from '@/endurance/tourSearch';
import { TourSearchState } from '@/redux/TourSearchState';
import { History } from 'history';

import { call, put, select } from 'redux-saga/effects';
import searchApi from '../../apis/tourSearchApi';
import {
    getQueryFromSearchParams,
    getSearchRequestFromSearchParams,
} from '../../services/tourSearchService';
import {
    performSearchFailure,
    performSearchSuccess,
    incrementPageSuccess,
    incrementPageFailure,
} from '../actions/tourSearch.actions';
import { RootState } from '../store';

import { sendEventV2 } from '../../utils/analytics/analyticsService';
import {
    Page,
    Action,
    InteractionItem,
    Area,
} from '../../utils/analytics/enums/EventInteractionEnums';

export const getState = (state: RootState) => state.tourSearch;
export const getSelectedCurrency = (state: RootState) => {
    return state.site.selectedCurrency ? state.site.selectedCurrency : 'GBP';
};

export function populateTourSearchParamsSaga(
    action: PopulateSearchParamsAction
) {
    if (action.updateQueryString) {
        updateSearchQueryString(action.params, action.history);
    }
}

export function* resetTourFiltersSaga(action: ResetFiltersAction) {
    const searchState: TourSearchState = yield select(getState);
    updateSearchQueryString(searchState.searchParams, action.history);
    const response: TourSearchResponse = yield search(
        action.sessionId,
        action.accessToken
    );

    yield put(
        response.success
            ? performSearchSuccess(response)
            : performSearchFailure('Error reseting filter results')
    );
}

export function* filterTourSearchSaga(action: FilterSearchAction) {
    updateSearchQueryString(action.params, action.history);
    const response: TourSearchResponse = yield search(
        action.sessionId,
        action.accessToken
    );

    yield put(
        response.success
            ? performSearchSuccess(response)
            : performSearchFailure('Error retrieving filter results')
    );
}

export function* performTourSearchSaga(action: SearchTourAction) {
    const response: TourSearchResponse = yield search(
        action.sessionId,
        action.accessToken
    );

    yield put(
        response.success
            ? performSearchSuccess(response)
            : performSearchFailure('Error retrieving search results')
    );
}

export function* incrementTourPageSaga(action: IncrementPageAction) {
    const response: TourSearchResponse = yield search(
        action.sessionId,
        action.accessToken
    );

    yield put(
        response.success
            ? incrementPageSuccess(response)
            : incrementPageFailure('Error retrieving page results')
    );
}

function* search(sessionId: string, accessToken: string | undefined) {
    const searchState: TourSearchState = yield select(getState);
    const selectedCurrency: string = yield select(getSelectedCurrency);
    const searchRequest: TourSearchRequest = getSearchRequestFromSearchParams(
        searchState.searchParams,
        sessionId,
        searchState.page,
        searchState.pageSize,
        selectedCurrency,
        true
    );

    const response: TourSearchResponse = yield call(
        searchApi.search,
        searchRequest,
        accessToken
    );

    const data = {
        searchParams: searchRequest,
        resultsCount: response?.totalResults ?? 0,
    };

    sendEventV2(
        Page.siteWide,
        Area.resultContainer,
        InteractionItem.searchResultsMetaData,
        Action.data,
        data
    );

    return response;
}

function updateSearchQueryString(
    searchParams: TourSearchParams | null,
    history: History
) {
    if (searchParams !== null) {
        history.push(`search?${getQueryFromSearchParams(searchParams)}`);
    }
}
