import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Icon from '../../common/Icon/Icon';
import SimpleSearchTool from '../../SimpleSearchTool/SimpleSearchTool';
import MobileMenuButton from '../MobileMenuButton/MobileMenuButton';
import MobileSearchMenuButton from '../MobileSearchMenuButton/MobileSearchMenuButton';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import EnquiryNumbers from '../../common/EnquiryNumbers/EnquiryNumbers';
import { selectTapTelephoneNumber } from '../../../redux/selectors/site.selectors';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { closeMobileMenu, closeMobileSearchMenu } from '../../../redux/actions';
import { sendEventV2 } from '../../../utils/analytics/analyticsService';
import {
    Page,
    Action,
    InteractionItem,
    Area,
} from '../../../utils/analytics/enums/EventInteractionEnums';
import styles from './HeaderTop.module.scss';

function HeaderTop({ className }) {
    const { telephoneNumber } = useAppSelector(state =>
        selectTapTelephoneNumber(state.site)
    );
    const dispatch = useAppDispatch();

    return (
        <nav className={classNames(styles.headerTop, className)}>
            <div className={styles.wrapper}>
                <Link className={styles.home} to="/" onClick={handleBrandClick}>
                    Home
                </Link>
                <SimpleSearchTool
                    className={styles.simpleSearchTool}
                    source="desktop-header"
                >
                    <Icon className={styles.buttonIcon} icon="search" />
                </SimpleSearchTool>
                <MobileSearchMenuButton
                    className={styles.mobileSearchMenuButton}
                />

                <div className={styles.rightContent}>
                    <EnquiryNumbers className={styles.enquiryNumbers} />
                    <a
                        className={classNames(styles.link, styles.phoneButton)}
                        href={`tel:${telephoneNumber}`}
                        aria-label="Phone us"
                    >
                        <Icon
                            className={styles.phoneIcon}
                            icon="phoneSolid"
                            height={null}
                            width={null}
                        />
                    </a>
                    <Link
                        className={classNames(
                            styles.link,
                            styles.favouriteButton
                        )}
                        to="/favourites"
                        aria-label="Favourites"
                        height={null}
                        width={null}
                        onClick={handleFavouritesClick}
                    >
                        <Icon
                            className={styles.favouriteIcon}
                            icon="emptyHeart"
                            height={null}
                            width={null}
                        />
                    </Link>
                    <Link
                        className={styles.link}
                        to="/myaccount"
                        aria-label="My Account"
                    >
                        <Icon
                            className={styles.accountIcon}
                            icon="userSolid"
                            height={null}
                            width={null}
                        />
                    </Link>
                    <HeaderMenu />
                </div>

                <MobileMenuButton className={styles.mobileMenuButton} />
            </div>
        </nav>
    );

    function handleBrandClick() {
        sendEventV2(
            Page.header,
            Area.homeLink,
            InteractionItem.homeLink,
            Action.click,
            null
        );

        resetMobileMenus();
    }

    function handleFavouritesClick() {
        sendEventV2(
            Page.header,
            Area.favourites,
            InteractionItem.favourites,
            Action.click,
            null
        );
        resetMobileMenus();
    }

    function resetMobileMenus() {
        dispatch(() => dispatch(closeMobileMenu()));
        dispatch(() => dispatch(closeMobileSearchMenu()));
    }
}

export default HeaderTop;
