import { useLocation } from 'react-router-dom';
import { useAppSelector } from './useAppSelector';

export interface SaleBanner {
    isLoading: boolean;
    isEnabled: boolean;
    config: {
        title: string;
        linkUrl: string | null;
    } | null;
}

function useSaleBanner(): SaleBanner {
    const isLoading = useAppSelector(({ site }) => site.isLoading);
    const saleBannerConfig = useAppSelector(({ site }) => site.saleBanner);
    const { pathname } = useLocation();

    const isExcludedUrl = excludedUrls.some(url => pathname.startsWith(url));

    return {
        isLoading: isLoading ?? true,
        isEnabled:
            saleBannerConfig !== null &&
            !!saleBannerConfig.title &&
            !isExcludedUrl,
        config:
            saleBannerConfig && saleBannerConfig.title
                ? {
                      title: saleBannerConfig.title,
                      linkUrl: saleBannerConfig.linkUrl,
                  }
                : null,
    };
}

const excludedUrls = ['/checkout/payment/', '/holdspace/'];

export default useSaleBanner;
