import * as classNames from 'classnames';
import Button, { ButtonProps } from '../Button/Button';
import styles from './RedWireButton.module.scss';

const RedWireButton = ({ className, ...props }: ButtonProps) => {
    return (
        <Button
            className={classNames(styles.redWireButton, className)}
            {...props}
        />
    );
};

export default RedWireButton;
