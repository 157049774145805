import { MouseEvent } from 'react';
import * as classNames from 'classnames';
import PrimaryButton, {
    PrimaryButtonProps,
} from '../PrimaryButton/PrimaryButton';
import styles from './PrimaryLoadingButton.module.scss';

const PrimaryLoadingButton = ({
    className,
    onClick,
    ...props
}: PrimaryButtonProps) => {
    return (
        <PrimaryButton
            className={classNames(styles.primaryLoadingButton, className)}
            onClick={(e: MouseEvent<HTMLElement>) => {
                e.preventDefault();
            }}
            {...props}
        >
            <div className={classNames(styles.loader)} />
        </PrimaryButton>
    );
};

export default PrimaryLoadingButton;
