import { AnchorHTMLAttributes, ReactNode } from 'react';
import { selectTapTelephoneNumber } from '../../../../redux/selectors/site.selectors';
import { useAppSelector } from '../../../../hooks/useAppSelector';

export interface TapPhoneNumberProps {
    prefix?: ReactNode;
    suffix?: ReactNode;
}

function TapPhoneNumber({
    prefix,
    suffix,
    ...props
}: TapPhoneNumberProps & AnchorHTMLAttributes<HTMLAnchorElement>) {
    const displayTelephoneNumber = useAppSelector(({ site }) => {
        const tapTelephoneNumber = selectTapTelephoneNumber(site);
        return (
            site?.selectedCurrencyData?.displayTelephoneNumber ||
            tapTelephoneNumber.displayTelephoneNumber
        );
    });

    const telephoneNumber = useAppSelector(({ site }) => {
        const tapTelephoneNumber = selectTapTelephoneNumber(site);
        return (
            site?.selectedCurrencyData?.telephoneNumber ||
            tapTelephoneNumber.telephoneNumber
        );
    });

    return (
        <a href={`tel:${telephoneNumber}`} {...props}>
            {!!prefix && prefix}
            {displayTelephoneNumber}
            {!!suffix && suffix}
        </a>
    );
}

export default TapPhoneNumber;
