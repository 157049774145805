import {
    SearchSuggestResponse,
    TourSearchRequest,
    TourSearchResponse,
} from '@/endurance/tourSearch';

import * as queryString from 'qs';
import searchHelper from '../helpers/searchHelper';
import { getResponse } from './enduranceApi';

export const searchUrl = '/tours/search';
export const tourSuggestionUrl = '/tours/search/suggest';

export function search(
    request: TourSearchRequest,
    accessToken: string | undefined
) {
    const filteredParams = searchHelper.removeUnusedEntries(request);
    const query = queryString.stringify(filteredParams, {
        encode: true,
        arrayFormat: 'comma',
    });

    return getResponse<TourSearchResponse>(
        `${searchUrl}?${query}`,
        accessToken
    );
}

export function suggestTours(text: string) {
    const query = queryString.stringify(
        { text: text },
        { encode: true, arrayFormat: 'comma' }
    );
    return getResponse<SearchSuggestResponse>(`${tourSuggestionUrl}?${query}`);
}

const tourSearchApi = {
    search,
    suggestTours,
};

export default tourSearchApi;
