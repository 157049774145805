import { ApiResponse } from '@/apis';
import { SimpleTour, TourDeparture } from '@/core';
import { TourSearchParams } from '@/core/tourSearch';
import { TourRoom } from '@/endurance/checkout';
import { BasicApiResponse } from '@/endurance';
import { SearchedTour } from '@/endurance/tourSearch';
import { BasicTour, Tour, SimilarToursResponse } from '@/endurance/tours';

import { getResponse, get } from './enduranceApi';
import tourSearchApi from './tourSearchApi';
import { getSearchRequestFromSearchParams } from '../services/tourSearchService';

export function getTour(
    tourId: string,
    currencyCode: string,
    departureId?: number,
    departureMonth?: string | null
) {
    if (!tourId) {
        return Promise.resolve({
            success: false,
            errors: [`TourId is required. TourId: '${tourId}'`],
            tour: null,
        });
    }

    let url = `/tours/${encodeURI(tourId)}?currencyCode=${currencyCode}`;

    if (departureId) {
        url += `&departureId=${departureId}`;
    }

    if (departureMonth) {
        url += `&departureMonth=${departureMonth}`;
    }

    return get<Tour>(url).then(response => ({
        success: response.isSuccess,
        errors: response.errors,
        tour: response.result,
    }));
}

export function getTourDepartures(tourId: string, currencyCode: string) {
    return get<TourDeparture[]>(
        `/tours/${encodeURI(tourId)}/departures?currencyCode=${currencyCode}`
    ).then(response => ({
        success: response.isSuccess,
        errors: response.errors,
        departures: response.result,
    }));
}

export function getTours(tourIds: string) {
    return get<BasicTour[]>(`/tours?tourIds=${encodeURI(tourIds)}`).then(
        response => ({
            success: response.isSuccess,
            error: response.errors,
            tours: response.result,
        })
    );
}

export function getTourRoomData(departureId: number, currencyCode: string) {
    return getResponse<BasicApiResponse<TourRoom[]>>(
        `/checkout/TourRoomTypes/${departureId}/${currencyCode}`
    )
        .then(data => {
            return data;
        })
        .catch(error => {
            throw error;
        });
}

export function getSimilarTours(
    tourId: string,
    currencyCode: string
): Promise<ApiResponse<SimpleTour[]>> {
    return getResponse<SimilarToursResponse>(
        `/tours/${encodeURI(tourId)}/similar?currencyCode=${currencyCode}`
    ).then(({ tours, success, errors }) => {
        const response: ApiResponse<SimpleTour[]> = {
            isSuccess: success,
            errors: errors ? errors : [],
            result: null,
        };

        if (tours) {
            try {
                response.result = tours.map(r => ({
                    tourId: r.tourId,
                    name: r.name,
                    image: { source: r.image, type: 'Tap', order: null },
                    sellingPrice: r.cheapestFromPrices.find(
                        p => p.currencyCode === currencyCode
                    ),
                    deposit: r.deposits.find(
                        p => p.currencyCode === currencyCode
                    ),
                    discount: r.discounts.find(
                        p => p.currencyCode === currencyCode
                    ),
                    discountPercent: r.discountPercent,
                    startCity: r.startCity,
                    finishCity: r.finishCity,
                    reviewCount: r.reviewCount,
                    reviewScore: r.reviewScore ?? 0,
                    minAge: r.minAge,
                    maxAge: r.maxAge,
                    minGroupSize: r.minGroupSize,
                    maxGroupSize: r.maxGroupSize,
                    attributes: r.attributes,
                    originalPrice: undefined,
                }));
            } catch (error) {
                response.isSuccess = false;
                response.errors = ['Unexpected error mapping Similar Tours.'];
            }
        }

        return response;
    });
}

export function getHighlightedTours(
    count: number,
    page: number,
    searchParams: TourSearchParams,
    currencyCode: string,
    sessionId: string,
    accessToken?: string
): Promise<SimpleTour[]> {
    const searchRequest = getSearchRequestFromSearchParams(
        searchParams,
        sessionId,
        page,
        count,
        currencyCode,
        false
    );

    return tourSearchApi
        .search(searchRequest, accessToken ?? '')
        .then(response => {
            if (!response.success) {
                throw new Error('Search failed');
            } else {
                return response.tours.map((searchResult: SearchedTour) => {
                    const adultPrices =
                        searchResult.headlineDeparture.prices.map(p => {
                            return {
                                currencyCode: p.currencyCode,
                                value: p.adultPrice,
                            };
                        });

                    const discounts = searchResult.headlineDeparture.prices.map(
                        p => ({
                            currencyCode: p.currencyCode,
                            value: p.discount,
                        })
                    );

                    const deposits = searchResult.headlineDeparture.prices.map(
                        p => ({
                            currencyCode: p.currencyCode,
                            value: p.deposit,
                        })
                    );

                    const originalPrice =
                        searchResult.headlineDeparture.prices.map(p => ({
                            currencyCode: p.currencyCode,
                            value: p.originalPrice,
                        }));

                    const discountPercent =
                        searchResult.headlineDeparture.prices.find(
                            p => p.currencyCode === currencyCode
                        )?.discountPercentage ?? 0;

                    const tour: SimpleTour = {
                        tourId: searchResult.tourId,
                        name: searchResult.name,
                        image: searchResult.image
                            ? {
                                  source: searchResult.image,
                                  type: 'Tap',
                                  order: null,
                              }
                            : null,
                        reviewScore: searchResult.reviewScore,
                        reviewCount: searchResult.reviewCount,
                        startCity: searchResult.startCity,
                        finishCity: searchResult.finishCity,
                        minAge: searchResult.minAge,
                        maxAge: searchResult.maxAge,
                        maxGroupSize: searchResult.maxGroupSize,
                        minGroupSize: searchResult.minGroupSize,
                        sellingPrice: adultPrices?.find(
                            a => a.currencyCode === currencyCode
                        ),
                        deposit: deposits?.find(
                            a => a.currencyCode === currencyCode
                        ),
                        attributes: searchResult.attributes,
                        discount: discounts?.find(
                            a => a.currencyCode === currencyCode
                        ),
                        discountPercent: discountPercent,
                        originalPrice: originalPrice?.find(
                            a => a.currencyCode === currencyCode
                        ),
                    };

                    return tour;
                });
            }
        });
}
