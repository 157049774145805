import {
    AirportsResponse,
    AirportSuggestionResponse,
} from '@/endurance/flights';
import { AirportsResult, AirportSuggestionsResult } from '@/apis/flightApi';

import * as queryString from 'qs';
import { getResponse } from './enduranceApi';

const airportsUrl = '/flights/airports';
const airportsByTourUrl = `${airportsUrl}/tour`;
const airportsByCountryUrl = `${airportsUrl}/country`;
const airportsDepartureUrl = `${airportsUrl}/departure`;

export const getAirports = (): Promise<AirportsResult> => {
    return getResponse<AirportsResponse>(airportsUrl).then(result => ({
        success: result.success,
        errors: result.errors ?? [],
        airports: result.airports,
        airportGroups: result.airportGroups,
    }));
};

export const suggestAirportsForTourArrival = (
    tourId: string,
    text: string
): Promise<AirportSuggestionsResult> => {
    const queryParams =
        (text && queryString.stringify({ text }, { addQueryPrefix: true })) ||
        '';

    return getResponse<AirportSuggestionResponse>(
        `${airportsByTourUrl}/${tourId}/suggestArrival${queryParams}`
    ).then(result => ({
        success: result.success,
        errors: result.errors ?? [],
        suggestions: result.suggestions,
        text: result.text,
    }));
};

export const suggestAirportsForTourReturn = (
    tourId: string,
    text: string
): Promise<AirportSuggestionsResult> => {
    const queryParams =
        (text && queryString.stringify({ text }, { addQueryPrefix: true })) ||
        '';

    return getResponse<AirportSuggestionResponse>(
        `${airportsByTourUrl}/${tourId}/suggestReturn${queryParams}`
    ).then(result => ({
        success: result.success,
        errors: result.errors ?? [],
        suggestions: result.suggestions,
        text: result.text,
    }));
};

export const suggestAirportsForCountry = (
    countryIsoCode2: string,
    text: string
): Promise<AirportSuggestionsResult> => {
    const queryParams =
        (text && queryString.stringify({ text }, { addQueryPrefix: true })) ||
        '';

    return getResponse<AirportSuggestionResponse>(
        `${airportsByCountryUrl}/${countryIsoCode2}/suggest${queryParams}`
    ).then(result => ({
        success: result.success,
        errors: result.errors ?? [],
        suggestions: result.suggestions,
        text: result.text,
    }));
};

export const suggestDepartureAirports = (
    text: string
): Promise<AirportSuggestionsResult> => {
    const queryParams =
        (text && queryString.stringify({ text }, { addQueryPrefix: true })) ||
        '';

    return getResponse<AirportSuggestionResponse>(
        `${airportsDepartureUrl}/suggest${queryParams}`
    ).then(result => ({
        success: result.success,
        errors: result.errors ?? [],
        suggestions: result.suggestions,
        text: result.text,
    }));
};

export const suggestTourDepartureAirports = (tourDepartureId: number) => {
    return getResponse<AirportsResponse>(
        `${airportsUrl}/tourDeparture/${tourDepartureId}`
    ).then(result => ({
        success: result.success,
        errors: result.errors ?? [],
        airports: result.airports,
        airportGroups: result.airportGroups,
    }));
};
