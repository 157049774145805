import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '../MenuItem/MenuItem';
import MenuLink from '../MenuLink/MenuLink';
import Icon from '../../common/Icon/Icon';
import useSaleBanner from '../../../hooks/useSaleBanner';
import { isPreRender } from '../../../utils/config';
import styles from './SubMenu.module.scss';

const SubMenu = ({
    to,
    children,
    links,
    isOpen,
    isMobileMenuActive,
    onBack,
    className,
    dispatch,
    onMouseLeave,
    ...props
}) => {
    const { isEnabled: saleBannerEnabled } = useSaleBanner();
    const renderSubMenu = isOpen || isPreRender();

    return (
        <div
            className={classNames(
                styles.subMenu,
                {
                    [styles.isOpen]: isOpen,
                    [styles[`col-${getColumnCount(links)}`]]: true,
                    [styles.saleBannerEnabled]: saleBannerEnabled,
                },
                className
            )}
            {...props}
        >
            {renderSubMenu && (
                <ul className={styles.items} onMouseLeave={onMouseLeave}>
                    <MenuItem
                        className={classNames(styles.link, styles.backButton)}
                    >
                        <button onClick={onBack}>
                            <Icon className={styles.icon} icon="chevron" />
                            Back
                        </button>
                    </MenuItem>
                    {to && (
                        <MenuLink
                            to={to}
                            className={classNames(styles.link, styles.parent)}
                        >
                            {children}
                        </MenuLink>
                    )}
                    {links.map(({ to, children, links, ...props }) => (
                        <MenuLink
                            key={children}
                            to={to}
                            links={links}
                            className={styles.link}
                            {...props}
                        >
                            {children}
                        </MenuLink>
                    ))}
                </ul>
            )}
        </div>
    );
};

function getColumnCount(links) {
    let columnCount = 0;
    if (links && links.length > 0) {
        if (links.length > 36) {
            columnCount = 4;
        } else if (links.length >= 22) {
            columnCount = 3;
        } else {
            columnCount = 2;
        }
    }

    return columnCount;
}

SubMenu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default SubMenu;
