import {
    TourSearchAggregations,
    TourSearchParams,
    SortType,
} from '@/core/tourSearch';
import { SearchedTour } from '@/endurance/tourSearch';
import { History } from 'history';

import {
    useState,
    useContext,
    createContext,
    ReactNode,
    useEffect,
    useCallback,
} from 'react';
import { getSearchParamsFromQuery } from '../services/tourSearchService';
import { useHistory, useLocation } from 'react-router-dom';
import {
    incrementPage,
    performSearch,
    populateSearchParams,
    resetSearch,
    resetFilters,
    filterTourSearch,
} from '../redux/actions';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { useSession } from './SessionContext';
import { useLogin } from './LoginContext';

export interface TourSearchResultsContextValue {
    hasInitialSearchComplete: boolean;
    isSearching: boolean;
    isPaging: boolean;
    isFilteringOrSorting: boolean;
    searchParams: TourSearchParams;
    results: SearchedTour[];
    totalResults: number;
    filterCount: number;
    appliedFilters: AppliedFilter[];
    sortType: SortType;
    aggregations: TourSearchAggregations | null;
    onLoadMoreResults: (pageNumber: number) => void;
    onFilterResults: (newSearchParams: TourSearchParams) => void;
    onResetFilters: () => void;
    populateSearchParams: (newSearchParams: TourSearchParams) => void;
}

export interface TourSearchResultsContextProps {
    children: ReactNode;
}

export interface AppliedFilter {
    name: string;
    keys: string[];
    value: string;
}

const TourSearchResultsContext =
    createContext<TourSearchResultsContextValue | null>(null);

export function useTourSearchResults() {
    const context = useContext(TourSearchResultsContext);
    if (!context) {
        throw new Error('No Tour Search Results Context initialised.');
    }
    return context;
}

export function TourSearchResultsProvider({
    children,
}: TourSearchResultsContextProps) {
    const history: History = useHistory();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { sessionId } = useSession();
    const { accessToken } = useLogin();

    const [hasInitialSearchStarted, setHasInitialSearchStarted] =
        useState<boolean>(false);
    const [hasInitialSearchComplete, setHasInitialSearchComplete] =
        useState<boolean>(false);
    const [filterCount, setFilterCount] = useState<number>(0);
    const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
    const {
        isSearching,
        results,
        totalResults,
        isPaging,
        isFilteringOrSorting,
        aggregations,
        searchParams,
    } = useAppSelector(state => state.tourSearch);

    const handleSearch = useCallback(
        (searchParams: TourSearchParams) => {
            setHasInitialSearchStarted(true);
            dispatch(resetSearch(sessionId));
            dispatch(populateSearchParams(searchParams, history));
            dispatch(performSearch(sessionId, accessToken));
        },
        [dispatch, history, sessionId]
    );

    // On main search criteria change treat as new search page landing
    useEffect(() => {
        const currentParams = getSearchParamsFromQuery(location.search);

        const hasSearchChanged =
            searchParams != null &&
            (currentParams.text !== searchParams.text ||
                currentParams.isAnywhere !== searchParams.isAnywhere ||
                currentParams.yearMonth !== searchParams.yearMonth ||
                currentParams.startDate !== searchParams.startDate ||
                currentParams.startDateFlexibility !==
                    searchParams.startDateFlexibility ||
                (currentParams.pageNumber != null
                    ? currentParams.pageNumber
                    : 1) <
                    (searchParams.pageNumber !== null
                        ? searchParams?.pageNumber
                        : 1));

        if (hasSearchChanged && hasInitialSearchComplete) {
            setHasInitialSearchStarted(false);
            setHasInitialSearchComplete(false);
        }
    }, [location.search, searchParams, hasInitialSearchComplete]);

    // Trigger search when search not started
    useEffect(() => {
        const params = getSearchParamsFromQuery(location.search);

        if (!hasInitialSearchStarted && !isSearching) {
            handleSearch(params);
        }
    }, [handleSearch, location.search, isSearching, hasInitialSearchStarted]);

    // Finish Search
    useEffect(() => {
        if (
            hasInitialSearchStarted &&
            !hasInitialSearchComplete &&
            !isSearching
        ) {
            setHasInitialSearchComplete(true);
        }
    }, [hasInitialSearchStarted, hasInitialSearchComplete, isSearching]);

    useEffect(() => {
        if (searchParams != null) {
            setFilterCount(
                searchParams.attributes.length +
                    searchParams.countries.length +
                    searchParams.regions.length +
                    searchParams.physicalRatings.length +
                    (searchParams.maxAge !== null ||
                    searchParams.minAge !== null ||
                    searchParams.familyOnly
                        ? 1
                        : 0) +
                    (searchParams.minPrice ? 1 : 0) +
                    (searchParams.maxPrice ? 1 : 0) +
                    (searchParams.minDuration ? 1 : 0) +
                    (searchParams.maxDuration ? 1 : 0) +
                    (searchParams.tourType ? 1 : 0)
            );
        }
    }, [searchParams]);

    useEffect(() => {
        if (searchParams != null) {
            const filters: AppliedFilter[] = [];
            if (searchParams.attributes.length > 0) {
                searchParams.attributes.forEach(attribute => {
                    filters.push({
                        name: attribute,
                        keys: ['attributes'],
                        value: attribute,
                    });
                });

                searchParams.countries.forEach(country => {
                    filters.push({
                        name: country, //TODO: Lookup name
                        keys: ['countries'],
                        value: country,
                    });
                });

                searchParams.regions.forEach(region => {
                    filters.push({
                        name: region, //TODO: Lookup name
                        keys: ['regions'],
                        value: region,
                    });
                });

                searchParams.physicalRatings.forEach(rating => {
                    filters.push({
                        name: `Physical rating: ${rating}`,
                        keys: ['physicalRatings'],
                        value: rating.toString(),
                    });
                });

                if (
                    searchParams.minAge != null &&
                    searchParams.maxAge != null
                ) {
                    filters.push({
                        name: `Age: ${searchParams.minAge}-${searchParams.maxAge}`,
                        keys: ['minAge', 'maxAge'],
                        value: `${searchParams.minAge}-${searchParams.maxAge}`,
                    });
                } else if (searchParams.minAge != null) {
                    filters.push({
                        name: `Min age: ${searchParams.minAge}`,
                        keys: ['minAge'],
                        value: searchParams.minAge.toString(),
                    });
                } else if (searchParams.maxAge != null) {
                    filters.push({
                        name: `Max age: ${searchParams.maxAge}`,
                        keys: ['maxAge'],
                        value: searchParams.maxAge.toString(),
                    });
                }

                if (searchParams.familyOnly) {
                    filters.push({
                        name: 'Family only',
                        keys: ['familyOnly'],
                        value: 'true',
                    });
                }
            }
            setAppliedFilters(filters);
        }
    }, [searchParams]);

    return (
        <TourSearchResultsContext.Provider
            value={{
                hasInitialSearchComplete,
                isSearching: isSearching,
                isPaging,
                isFilteringOrSorting,
                searchParams:
                    searchParams ?? getSearchParamsFromQuery(location.search),
                results: results,
                totalResults,
                sortType: searchParams.sortType ?? 'Recommended',
                filterCount,
                appliedFilters,
                aggregations,
                onLoadMoreResults: handleLoadMore,
                onResetFilters: handleResetFilters,
                onFilterResults: handleFilterResults,
                populateSearchParams: handlePopulateSearchParams,
            }}
        >
            {children}
        </TourSearchResultsContext.Provider>
    );

    function handleLoadMore(pageNumber: number) {
        dispatch(incrementPage(sessionId, pageNumber, accessToken));
    }

    function handleResetFilters() {
        dispatch(resetFilters(sessionId, history));
    }

    function handleFilterResults(newParams: TourSearchParams) {
        //Always reset to page 1 when filtering
        newParams.pageNumber = 1;
        dispatch(filterTourSearch(newParams, sessionId, history, accessToken));
    }

    function handlePopulateSearchParams(newParams: TourSearchParams) {
        dispatch(populateSearchParams(newParams, history));
    }
}
