import * as classNames from 'classnames';
import Button, { ButtonProps } from '../Button/Button';
import Icon, { Icons } from '../../Icon/Icon';
import styles from './SecondaryButton.module.scss';

export type SecondaryButtonProps = {
    icon?: keyof Icons;
    width?: number;
    height?: number;
} & ButtonProps;

const SecondaryButton = ({
    icon,
    width = 20,
    height = 20,
    color = 'fff',
    children,
    className,
    ...props
}: SecondaryButtonProps) => {
    return (
        <Button
            className={classNames(styles.secondaryButton, className)}
            {...props}
        >
            <span className={styles.children}>{children}</span>
            {!!icon && (
                <Icon
                    className={styles.icon}
                    icon={icon}
                    width={width}
                    height={height}
                    color={color}
                />
            )}
        </Button>
    );
};

export default SecondaryButton;
