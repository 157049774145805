import { MouseEvent } from 'react';
import * as classNames from 'classnames';
import SecondaryButton, {
    SecondaryButtonProps,
} from '../SecondaryButton/SecondaryButton';
import styles from './SecondaryLoadingButton.module.scss';

const SecondaryLoadingButton = ({
    className,
    onClick,
    ...props
}: SecondaryButtonProps) => {
    return (
        <SecondaryButton
            className={classNames(styles.secondaryLoadingButton, className)}
            onClick={(e: MouseEvent<HTMLElement>) => {
                e.preventDefault();
            }}
            {...props}
        >
            <div className={classNames(styles.loader)} />
        </SecondaryButton>
    );
};

export default SecondaryLoadingButton;
