enum Page {
    siteWide = 'SiteWide',
    homePage = 'HomePage',
    tourSearchResultsPage = 'TourSearchResultsPage',
    tourPage = 'TourPage',
    contactUs = 'ContactUs',
    tourContactUs = 'TourContactUs',
    flightContactUs = 'FlightContactUs',
    accommodationSearch = 'AccommodationSearch',
    activities = 'Activities',
    blogHub = 'BlogHub',
    blogSinglePost = 'BlogSinglePost',
    deal = 'Deal',
    departureUnavailable = 'DepartureUnavailable',
    destinations = 'Destinations',
    FAQs = 'FAQs',
    iconicTours = 'IconicTours',
    notFound = 'NotFound',
    popularDestinations = 'PopularDestinations',
    holdSpaceReserve = 'HoldSpaceReserve',
    holdSpaceConvert = 'HoldSpaceConvert',
    tourCheckout = 'TourCheckout',
    header = 'Header',
    footer = 'Footer',
    allDeals = 'AllDeals',
    deals = 'Deals',
    specificDestination = 'SpecificDestination',
    destinationCategory = 'DestinationCategory',
    activityCategory = 'ActivityCategory',
    allActivities = 'AllActivities',
    specificActivity = 'SpecificActivity',
    popularActivities = 'PopularActivities',
    blackFriday = 'BlackFriday',
    januarySale = 'JanuarySale',
    tailorMadeEnquiry = 'TailorMadeEnquiry',
    flightEnquiry = 'FlightEnquiry',
    enquiry = 'Enquiry',
    tourEnquiry = 'TourEnquiry',
    tourPackages = 'TourPackages',
    tradeSignUp = 'tradeSignUp',
    favourites = 'Favourites',
}

const PageMinifiedMap: { [key in Page]: string } = {
    [Page.siteWide]: 'SW',
    [Page.homePage]: 'HP',
    [Page.tourSearchResultsPage]: 'TSRP',
    [Page.tourPage]: 'TP',
    [Page.contactUs]: 'CU',
    [Page.tourContactUs]: 'TCU',
    [Page.flightContactUs]: 'FCU',
    [Page.accommodationSearch]: 'AS',
    [Page.activities]: 'AP',
    [Page.blogHub]: 'BH',
    [Page.blogSinglePost]: 'BSP',
    [Page.deal]: 'DL',
    [Page.departureUnavailable]: 'DU',
    [Page.destinations]: 'DS',
    [Page.FAQs]: 'FAQ',
    [Page.iconicTours]: 'IT',
    [Page.notFound]: 'NF',
    [Page.popularDestinations]: 'PD',
    [Page.holdSpaceReserve]: 'HSR',
    [Page.holdSpaceConvert]: 'HSC',
    [Page.header]: 'HEAD',
    [Page.footer]: 'FOOT',
    [Page.deals]: 'DLS',
    [Page.specificDestination]: 'SD',
    [Page.destinationCategory]: 'DC',
    [Page.allActivities]: 'AA',
    [Page.specificActivity]: 'SA',
    [Page.popularActivities]: 'PA',
    [Page.blackFriday]: 'BF',
    [Page.allDeals]: 'AD',
    [Page.januarySale]: 'JS',
    [Page.tourCheckout]: 'TC',
    [Page.tailorMadeEnquiry]: 'TME',
    [Page.flightEnquiry]: 'FE',
    [Page.enquiry]: 'ENQ',
    [Page.tourEnquiry]: 'TE',
    [Page.tourPackages]: 'TP',
    [Page.activityCategory]: 'AC',
    [Page.tradeSignUp]: 'TSU',
    [Page.favourites]: 'FAV',
};

export { Page, PageMinifiedMap };
