import { TourSearchAggregations, TourSearchParams } from '@/core/tourSearch';
import { TourSearchResponseAggregations } from '@/endurance/tourSearch';
import {
    FilterSearchAction,
    IncrementPageAction,
    IncrementPageFailureAction,
    IncrementPageSuccessAction,
    PopulateSearchParamsAction,
    ResetFiltersAction,
    ResetSearchAction,
    SearchTourFailureAction,
    SearchTourSuccessAction,
    SortSearchAction,
} from '@/redux/actions/tourSearch';
import { TourSearchState } from '@/redux/TourSearchState';
import { Action } from 'redux';
import * as TYPES from '../actions/types';

const initialSearchParams: TourSearchParams = {
    text: '',
    minAge: null,
    maxAge: null,
    minPrice: null,
    maxPrice: null,
    minDuration: null,
    maxDuration: null,
    startDate: null,
    startDateFlexibility: null,
    yearMonth: null,
    attributes: [],
    countries: [],
    regions: [],
    physicalRatings: [],
    familyOnly: false,
    isAnywhere: false,
    source: null,
    tourType: null,
    sortType: null,
    hasPrivateRoom: false,
    pageNumber: 1,
    loadResultsBeforePageNumber: false,
    guideStyles: [],
};

const initialSearchAggregations: TourSearchAggregations = {
    spaces: null,
    physicalRatings: [],
    age: null,
    duration: null,
    startDate: null,
    price: null,
    attributeGroups: [],
    privateRooms: null,
    regions: [],
    tourTypes: [],
    guideStyles: [],
};

const initialState: TourSearchState = {
    searchParams: initialSearchParams,
    originalSearchParams: null,
    aggregations: initialSearchAggregations,
    originalAggregations: null,
    isSearching: false,
    isFilteringOrSorting: false,
    isPaging: false,
    page: 1,
    pageSize: 18,
    results: [],
    totalResults: 0,
    error: null,
};

function getCurrentSearchParams(params: TourSearchParams) {
    return {
        ...initialSearchParams,
        text: params.text,
        isAnywhere: params.isAnywhere,
        minAge: params.minAge,
        maxAge: params.maxAge,
        minPrice: params.minPrice,
        maxPrice: params.maxPrice,
        minDuration: params.minDuration,
        maxDuration: params.maxDuration,
        startDate: params.startDate,
        startDateFlexibility: params.startDateFlexibility,
        yearMonth: params.yearMonth,
        attributes: params.attributes,
        countries: params.countries,
        regions: params.regions,
        physicalRatings: params.physicalRatings,
        familyOnly: params.familyOnly,
        source: params.source,
        hasPrivateRoom: params.hasPrivateRoom,
        pageNumber: params.pageNumber,
        tourType: params.tourType,
        loadResultsBeforePageNumber: params.loadResultsBeforePageNumber,
        guideStyles: params.guideStyles,
    };
}

function resetSearch(
    _: TourSearchState,
    __: ResetSearchAction
): TourSearchState {
    return {
        ...initialState,
        results: _.results,
    };
}

function resetFilters(
    state: TourSearchState,
    _: ResetFiltersAction
): TourSearchState {
    return {
        ...state,
        searchParams: state.originalSearchParams
            ? state.originalSearchParams
            : state.searchParams,
        aggregations: state.originalAggregations,
        page: initialState.page,
    };
}

function populateSearchParams(
    state: TourSearchState,
    action: PopulateSearchParamsAction
): TourSearchState {
    const params = getCurrentSearchParams(action.params);

    return {
        ...state,
        searchParams: params,
    };
}

function filterSearch(
    state: TourSearchState,
    action: FilterSearchAction
): TourSearchState {
    const params = getCurrentSearchParams(action.params);

    return {
        ...state,
        searchParams: params,
        originalSearchParams: state.originalSearchParams
            ? state.originalSearchParams
            : params,
        page: initialState.page,
        isSearching: true,
        isFilteringOrSorting: true,
    };
}

function sortSearch(
    state: TourSearchState,
    action: SortSearchAction
): TourSearchState {
    return {
        ...state,
        page: initialState.page,
        searchParams: {
            ...state.searchParams,
            sortType: action.sortType,
        },
        isSearching: true,
        isFilteringOrSorting: true,
    };
}

function incrementPage(
    state: TourSearchState,
    _: IncrementPageAction
): TourSearchState {
    return {
        ...state,
        isSearching: true,
        isPaging: true,
        page: _.pageNumber,
        searchParams: {
            ...state.searchParams,
            pageNumber: _.pageNumber,
        },
    };
}

function incrementPageSuccess(
    state: TourSearchState,
    action: IncrementPageSuccessAction
): TourSearchState {
    return {
        ...state,
        results: [...state.results, ...action.response.tours],
        totalResults: action.response.totalResults,
        aggregations: parseResponseAggregations(action.response.aggregations),
        originalAggregations:
            state.originalAggregations === null &&
            action.response.totalResults > 0
                ? parseResponseAggregations(action.response.aggregations)
                : state.originalAggregations,
        isSearching: false,
        isPaging: false,
    };
}

function incrementPageFailure(
    state: TourSearchState,
    action: IncrementPageFailureAction
): TourSearchState {
    return {
        ...state,
        isSearching: false,
        isPaging: false,
        error: action.error,
    };
}

function performSearch(state: TourSearchState): TourSearchState {
    return {
        ...state,
        originalSearchParams: initialState.originalSearchParams,
        originalAggregations: initialState.originalAggregations,
        isSearching: true,
    };
}

function performSearchSuccess(
    state: TourSearchState,
    action: SearchTourSuccessAction
): TourSearchState {
    return {
        ...state,
        results: action.response.tours,
        totalResults: action.response.totalResults,
        aggregations: parseResponseAggregations(action.response.aggregations),
        originalSearchParams:
            state.originalSearchParams === null
                ? state.searchParams
                : state.originalSearchParams,
        originalAggregations:
            state.originalAggregations === null &&
            action.response.totalResults > 0
                ? parseResponseAggregations(action.response.aggregations)
                : state.originalAggregations,
        isSearching: false,
        isFilteringOrSorting: false,
        error: null,
        searchParams: {
            ...state.searchParams,
            loadResultsBeforePageNumber: false,
        },
    };
}

function performSearchFailure(
    state: TourSearchState,
    action: SearchTourFailureAction
): TourSearchState {
    return {
        ...state,
        originalSearchParams: state.searchParams,
        isSearching: false,
        isFilteringOrSorting: false,
        error: action.error,
    };
}

function parseResponseAggregations(
    aggregations: TourSearchResponseAggregations
): TourSearchAggregations {
    return {
        spaces: aggregations.spaces,
        physicalRatings: aggregations.physicalRatings.map(
            ({ value, name, count }) => ({
                value: parseInt(value),
                name: name,
                count,
            })
        ),
        age: aggregations.age,
        duration: aggregations.duration,
        startDate: aggregations.startDate,
        price: aggregations.prices,
        attributeGroups: aggregations.attributeGroups,
        privateRooms: {
            value: aggregations.privateRooms.value,
            name: 'Private',
            count: aggregations.privateRooms.count,
        },
        regions: aggregations.regions.map(
            ({ value, name, count, countries }) => ({
                value: value,
                name: name,
                count: count,
                countries: countries,
            })
        ),
        tourTypes: aggregations.tourTypes,
        guideStyles: aggregations.guideStyles,
    };
}

const tourSearch = (state = initialState, action: Action) => {
    const handlers: any = {
        [TYPES.RESET_SEARCH]: resetSearch,
        [TYPES.RESET_FILTERS]: resetFilters,
        [TYPES.POPULATE_SEARCH_PARAMS]: populateSearchParams,
        [TYPES.PERFORM_SEARCH]: performSearch,
        [TYPES.PERFORM_SEARCH_SUCCESS]: performSearchSuccess,
        [TYPES.PERFORM_SEARCH_FAILURE]: performSearchFailure,
        [TYPES.INCREMENT_PAGE]: incrementPage,
        [TYPES.INCREMENT_PAGE_SUCCESS]: incrementPageSuccess,
        [TYPES.INCREMENT_PAGE_FAILURE]: incrementPageFailure,
        [TYPES.FILTER_SEARCH]: filterSearch,
        [TYPES.SORT_SEARCH]: sortSearch,
    };

    const handler: (site: TourSearchState, action?: Action) => TourSearchState =
        handlers[action.type];

    return handler ? handler(state, action) : state;
};

export default tourSearch;
