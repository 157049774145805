import {
    AnchorHTMLAttributes,
    ButtonHTMLAttributes,
    ElementType,
    RefAttributes,
} from 'react';
import { Link, LinkProps } from 'react-router-dom';
import * as classNames from 'classnames';
import styles from './Button.module.scss';

export type ButtonProps =
    | HtmlButtonProps
    | HtmlAnchorProps
    | ReactRouterDomLinkProps
    | HtmlDivProps;

const Button = ({ as, className, children, ...props }: ButtonProps) => {
    const Component = (as ?? 'button') as ElementType;
    return (
        <Component className={classNames(styles.button, className)} {...props}>
            {children}
        </Component>
    );
};

export type ButtonBase<C extends 'button' | 'a' | typeof Link | 'div'> = {
    as?: C;
};

export type HtmlButtonProps = ButtonBase<'button'> &
    RefAttributes<HTMLButtonElement> &
    ButtonHTMLAttributes<HTMLButtonElement>;
export type HtmlAnchorProps = ButtonBase<'a'> &
    RefAttributes<HTMLAnchorElement> &
    AnchorHTMLAttributes<HTMLAnchorElement>;
export type ReactRouterDomLinkProps = ButtonBase<typeof Link> & LinkProps;
export type HtmlDivProps = ButtonBase<'div'> &
    RefAttributes<HTMLDivElement> &
    AnchorHTMLAttributes<HTMLDivElement>;

export default Button;
